import { appProvider } from '@/app-providers'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { PoolStore } from '../stores/pool-store'
import { poolsStore } from '../stores/pools-store'
export class IdoPoolsViewModel {
  @observable activeVideoIndex = 0
  @observable page = 1
  @observable itemsPerPage = 6
  @observable loading = false
  constructor() {
    this.fetchPools()
  }

  @asyncAction *fetchPools() {
    this.loading = true
    yield poolsStore.fetchPools()
    this.loading = false
  }

  @action changeActiveVideo(index) {
    this.activeVideoIndex = index
  }

  @action compareSaleDate(a: PoolStore, b: PoolStore) {
    const startDate1 = moment(a.pool.startDate)
    const startDate2 = moment(b.pool.startDate)
    if (startDate1.isBefore(startDate2)) return 1
    if (startDate1.isAfter(startDate2)) return -1
    return 0
  }

  @computed get upcommingsPools() {
    return poolsStore.validPools.filter((p) => !p.pool.address && moment(p.pool.startDate).isAfter(moment()))
  }
  @computed get featuredPools() {
    return poolsStore.validPools.filter((p) => p.pool.address && moment().isBefore(moment(p.pool.endDate)))
  }
  @computed get closedPools() {
    return poolsStore.validPools.filter((p) => moment(p.pool.endDate).isBefore(moment()))
  }
  @computed get hasUpcommings() {
    return !!this.upcommingsPools.length
  }
  @computed get hasFeaturedPools() {
    return !!this.featuredPools.length
  }
  @computed get hasClosedPools() {
    return !!this.closedPools.length
  }
  @computed get sliceClosedPools() {
    return this.closedPools.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
  }

  @computed get totalPage() {
    const totalPools = this.closedPools.length
    if (!this.itemsPerPage) return 0
    if (totalPools % this.itemsPerPage == 0) return totalPools / this.itemsPerPage
    else return Math.floor(totalPools / this.itemsPerPage) + 1
  }

  @action.bound goToProjectDetail(item) {
    appProvider.router.push(`/project/${item.pool.unicodeName}`)
  }

  @computed get trailerPools() {
    return poolsStore.validPools
      .filter((p) => moment().isBefore(moment(p.pool.endDate)) && p.pool?.data?.trailerVideo)
      .sort((a, b) => this.compareSaleDate(a, b))
  }

  @computed get activeTrailerPool() {
    return this.trailerPools[this.activeVideoIndex]
  }

  @computed get activeVideo() {
    return this.activeTrailerPool?.pool?.data?.trailerVideo
  }

  @computed get trailerWeb() {
    return this.activeTrailerPool?.pool?.data?.web
  }
  @computed get trailerMedium() {
    return this.activeTrailerPool?.pool?.data?.medium
  }
  @computed get trailerTelegram() {
    return this.activeTrailerPool?.pool?.data?.telegram
  }
  @computed get trailerTwitter() {
    return this.activeTrailerPool?.pool?.data?.twitter
  }
  @computed get trailerDiscord() {
    return this.activeTrailerPool?.pool?.data?.discord
  }
  @computed get trailerFacebook() {
    return this.activeTrailerPool?.pool?.data?.facebook
  }
  @computed get trailerTwitch() {
    return this.activeTrailerPool?.pool?.data?.twitch
  }
  @computed get trailerInstagram() {
    return this.activeTrailerPool?.pool?.data?.instagram
  }
  @computed get trailerYoutube() {
    return this.activeTrailerPool?.pool?.data?.youtube
  }
  @computed get trailerReddit() {
    return this.activeTrailerPool?.pool?.data?.reddit
  }
  @computed get trailerTiktok() {
    return this.activeTrailerPool?.pool?.data?.tiktok
  }
  @computed get trailerLinkedIn() {
    return this.activeTrailerPool?.pool?.data?.linkedIn
  }
  @computed get trailerBlog() {
    return this.activeTrailerPool?.pool?.data?.blog
  }
}
