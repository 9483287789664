<template>
  <div
    class="fill-height d-flex flex-column"
    :class="{
      'root-dark': $vuetify.theme.dark,
      'root-light': !$vuetify.theme.dark,
    }"
    v-if="true"
  >
    <div class="background-1 flex-fill">
      <div
        :class="{
          'golden-border-polinate-banner': $vuetify.theme.dark,
        }"
      >
        <div class="background-2">
          <v-container
            class="d-flex flex-column px-6"
            :class="{
              'py-16': $vuetify.breakpoint.smAndUp,
              'py-8': $vuetify.breakpoint.xs,
            }"
          >
            <div
              class="white--text"
              :class="{ 'mt-16': $vuetify.breakpoint.xsOnly, 'mt-12': $vuetify.breakpoint.smOnly }"
            >
              <span
                :class="{
                  'font-weight-bold page-title primary--text': $vuetify.breakpoint.smAndUp,
                  'text-h5 font-weight-bold primary--text': $vuetify.breakpoint.xs,
                }"
                >Waggle Pollinate <br
              /></span>
              <div
                class="mt-3 font-weight-regular"
                :class="{
                  'text-h5': $vuetify.breakpoint.smAndUp,
                  'text-caption': $vuetify.breakpoint.xs,
                }"
              >
                Unlocking opportunities to be part of exclusive deals, <br />specially curated for the Waggle community
              </div>
            </div>
          </v-container>
        </div>
      </div>
      <v-container class="pa-6 pb-8 pools-container">
        <div class="text-h4 font-weight-bold" v-if="vm.trailerPools.length > 0">Featured Projects</div>
        <trailer-videos-mobile class="mt-6 mb-12" v-if="$vuetify.breakpoint.smAndDown" />
        <trailer-videos class="mt-6 mb-12" v-else />
        <v-row>
          <v-col v-if="vm.hasFeaturedPools" cols="12" class="text-h4 font-weight-bold">Live Projects</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.featuredPools" :key="model.pool.id">
            <featured-pool-item :model="model" />
          </v-col>
          <v-col cols="12" v-if="vm.hasFeaturedPools" />
          <v-col v-if="vm.hasUpcommings" cols="12" class="text-h4 font-weight-bold">Upcoming Projects</v-col>
          <v-col cols="12" sm="6" md="4" v-for="model in vm.upcommingsPools" :key="model.pool.id">
            <upcoming-pool-item :model="model" />
          </v-col>
          <v-col cols="12" v-if="vm.hasUpcommings" />

          <v-col v-if="vm.hasClosedPools" cols="12" class="text-h4 font-weight-bold">Completed Projects</v-col>
          <!-- <v-col cols="12" sm="6" md="4" v-for="model in vm.closedPools" :key="model.pool.id">
            <featured-pool-item :model="model" />
          </v-col> -->
          <v-col cols="12">
            <v-data-table
              :items="vm.sliceClosedPools"
              :headers="closedPoolHeaders"
              :items-per-page="vm.itemsPerPage"
              :loading="vm.loading"
              class="font-weight-bold text-body-1 transparent-bg border-radius-16 table-hover closedPoolsTable"
              :class="{
                'gray-border': $vuetify.theme.dark,
                'white-border': !$vuetify.theme.dark,
              }"
              hide-default-footer
              @click:row="vm.goToProjectDetail"
            >
              <template v-slot:[`header.pool.name`]="{ header }">
                <div :class="$vuetify.breakpoint.xs ? 'text-start' : 'text-center'">
                  {{ header.text }}
                </div>
              </template>
              <template v-slot:[`item.pool.name`]="{ item }">
                <div
                  :class="{
                    'd-flex align-center': $vuetify.breakpoint.smAndUp,
                    'd-flex flex-column align-end': $vuetify.breakpoint.xs,
                  }"
                >
                  <v-responsive
                    :aspect-ratio="3 / 2"
                    class="pool-image-card-mini my-2"
                    style="max-width: 105px"
                    :class="{
                      'mr-3': $vuetify.breakpoint.smAndUp,
                    }"
                  >
                    <div class="pool-image-border-mini" v-if="!!item.coverUrl"></div>
                    <v-img v-if="!!item.coverUrl" :src="item.coverUrl" width="100%" height="100%"></v-img>
                    <div v-else>
                      <v-img
                        :src="item.coverUrl ? item.coverUrl : require(`@/assets/images/cover-card-default.svg`)"
                        width="100%"
                        height="100%"
                      >
                      </v-img>
                    </div>
                  </v-responsive>
                  <div class="d-flex align-center">
                    <app-logo :avatar="item.pool.logoUrl || item.pool.file" :width="24" contain class="mr-2" />
                    {{ item.projectName }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.pool.chain`]="{ item }">
                <chain-logo :chain="item.pool.chain" width="20" />
              </template>
              <template v-slot:[`item.totalPurchasedInTradeToken`]="{ item }">
                {{ item.totalPurchasedInTradeToken | round(0) | usdCustom(0, 0) }}
              </template>
              <template v-slot:[`item.tradeValue`]="{ item }">
                {{ item.tradeValue | usdCustom(0) }}
              </template>
              <template v-slot:[`item.roiLevel`]="{ item }">
                <div
                  v-if="item.roiLevel === 'Refunded'"
                  class="d-flex align-center justify-center text-h5 font-weight-bold success--text"
                >
                  Refunded
                </div>
                <div
                  v-else-if="item.roiLevel && +item.roiLevel > 0"
                  class="d-flex align-center success--text justify-center text-h5 font-weight-bold"
                  style="margin-left: -16px"
                >
                  <v-icon color="success" class="mr-1">mdi-arrow-up</v-icon>
                  {{ item.roiLevel }}X
                </div>
                <!-- <div
                  v-if="item.roiLevel && +item.roiLevel < 0"
                  class="d-flex align-center error--text justify-center font-size-20"
                  style="margin-left: -16px"
                >
                  <v-icon small color="error" class="mr-1">mdi-arrow-down</v-icon>
                  {{ Math.abs(+item.roiLevel) }}x
                </div> -->
              </template>
            </v-data-table>
            <v-pagination v-model="vm.page" :length="vm.totalPage" class="mt-6" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <company-footer />
  </div>
  <div v-else>
    <ComingSoon />
  </div>
</template>

<script>
import FeaturedPoolItem from '../components/featured-pool-item.vue'
import UpcomingPoolItem from '../components/upcoming-pool-item.vue'
import ComingSoon from '@/modules/common/pages/coming-soon.vue'
import { IdoPoolsViewModel } from '../viewmodels/ido-pools-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    UpcomingPoolItem,
    FeaturedPoolItem,
    ComingSoon,
    PoolCover: () => import('@/components/images/pool-cover.vue'),
    TrailerVideos: () => import('../components/trailer-videos.vue'),
    TrailerVideosMobile: () => import('../components/trailer-videos-mobile.vue'),
  },
})
export default class IdoPools extends Vue {
  @Provide() vm = new IdoPoolsViewModel()

  closedPoolHeaders = [
    {
      value: 'pool.name',
      text: 'Project',
      sortable: false,
    },
    {
      value: 'pool.chain',
      text: 'Chain',
      sortable: false,
      align: 'center',
    },
    {
      value: 'totalPurchasedInTradeToken',
      text: 'Total Raise',
      sortable: false,
      align: 'center',
    },
    {
      value: 'participants',
      text: 'Participants',
      sortable: false,
      align: 'center',
    },
    {
      value: 'tradeValue',
      text: 'Price Ratio',
      sortable: false,
      align: 'center',
    },
    {
      value: 'roiLevel',
      text: 'ROI (ATH)',
      sortable: true,
      align: 'center',
    },
  ]
}
</script>

<style lang="scss" scoped>
.pools-container {
  min-height: 800px;
}

.page-title {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 40px;
  letter-spacing: 0.01em;
}

.golden-border-polinate-banner {
  background-image: linear-gradient(90deg, rgba(241, 177, 1, 0) 15.52%, #f1b101 55.15%, rgba(241, 177, 1, 0) 95.35%);
  padding-bottom: 1.5px;
}
</style>
