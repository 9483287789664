var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (true)?_c('div',{staticClass:"fill-height d-flex flex-column",class:{
    'root-dark': _vm.$vuetify.theme.dark,
    'root-light': !_vm.$vuetify.theme.dark,
  }},[_c('div',{staticClass:"background-1 flex-fill"},[_c('div',{class:{
        'golden-border-polinate-banner': _vm.$vuetify.theme.dark,
      }},[_c('div',{staticClass:"background-2"},[_c('v-container',{staticClass:"d-flex flex-column px-6",class:{
            'py-16': _vm.$vuetify.breakpoint.smAndUp,
            'py-8': _vm.$vuetify.breakpoint.xs,
          }},[_c('div',{staticClass:"white--text",class:{ 'mt-16': _vm.$vuetify.breakpoint.xsOnly, 'mt-12': _vm.$vuetify.breakpoint.smOnly }},[_c('span',{class:{
                'font-weight-bold page-title primary--text': _vm.$vuetify.breakpoint.smAndUp,
                'text-h5 font-weight-bold primary--text': _vm.$vuetify.breakpoint.xs,
              }},[_vm._v("Waggle Pollinate "),_c('br')]),_c('div',{staticClass:"mt-3 font-weight-regular",class:{
                'text-h5': _vm.$vuetify.breakpoint.smAndUp,
                'text-caption': _vm.$vuetify.breakpoint.xs,
              }},[_vm._v(" Unlocking opportunities to be part of exclusive deals, "),_c('br'),_vm._v("specially curated for the Waggle community ")])])])],1)]),_c('v-container',{staticClass:"pa-6 pb-8 pools-container"},[(_vm.vm.trailerPools.length > 0)?_c('div',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Featured Projects")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('trailer-videos-mobile',{staticClass:"mt-6 mb-12"}):_c('trailer-videos',{staticClass:"mt-6 mb-12"}),_c('v-row',[(_vm.vm.hasFeaturedPools)?_c('v-col',{staticClass:"text-h4 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Live Projects")]):_vm._e(),_vm._l((_vm.vm.featuredPools),function(model){return _c('v-col',{key:model.pool.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('featured-pool-item',{attrs:{"model":model}})],1)}),(_vm.vm.hasFeaturedPools)?_c('v-col',{attrs:{"cols":"12"}}):_vm._e(),(_vm.vm.hasUpcommings)?_c('v-col',{staticClass:"text-h4 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Upcoming Projects")]):_vm._e(),_vm._l((_vm.vm.upcommingsPools),function(model){return _c('v-col',{key:model.pool.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('upcoming-pool-item',{attrs:{"model":model}})],1)}),(_vm.vm.hasUpcommings)?_c('v-col',{attrs:{"cols":"12"}}):_vm._e(),(_vm.vm.hasClosedPools)?_c('v-col',{staticClass:"text-h4 font-weight-bold",attrs:{"cols":"12"}},[_vm._v("Completed Projects")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"font-weight-bold text-body-1 transparent-bg border-radius-16 table-hover closedPoolsTable",class:{
              'gray-border': _vm.$vuetify.theme.dark,
              'white-border': !_vm.$vuetify.theme.dark,
            },attrs:{"items":_vm.vm.sliceClosedPools,"headers":_vm.closedPoolHeaders,"items-per-page":_vm.vm.itemsPerPage,"loading":_vm.vm.loading,"hide-default-footer":""},on:{"click:row":_vm.vm.goToProjectDetail},scopedSlots:_vm._u([{key:"header.pool.name",fn:function(ref){
            var header = ref.header;
return [_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'text-start' : 'text-center'},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.pool.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:{
                  'd-flex align-center': _vm.$vuetify.breakpoint.smAndUp,
                  'd-flex flex-column align-end': _vm.$vuetify.breakpoint.xs,
                }},[_c('v-responsive',{staticClass:"pool-image-card-mini my-2",class:{
                    'mr-3': _vm.$vuetify.breakpoint.smAndUp,
                  },staticStyle:{"max-width":"105px"},attrs:{"aspect-ratio":3 / 2}},[(!!item.coverUrl)?_c('div',{staticClass:"pool-image-border-mini"}):_vm._e(),(!!item.coverUrl)?_c('v-img',{attrs:{"src":item.coverUrl,"width":"100%","height":"100%"}}):_c('div',[_c('v-img',{attrs:{"src":item.coverUrl ? item.coverUrl : require("@/assets/images/cover-card-default.svg"),"width":"100%","height":"100%"}})],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('app-logo',{staticClass:"mr-2",attrs:{"avatar":item.pool.logoUrl || item.pool.file,"width":24,"contain":""}}),_vm._v(" "+_vm._s(item.projectName)+" ")],1)],1)]}},{key:"item.pool.chain",fn:function(ref){
                  var item = ref.item;
return [_c('chain-logo',{attrs:{"chain":item.pool.chain,"width":"20"}})]}},{key:"item.totalPurchasedInTradeToken",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("usdCustom")(_vm._f("round")(item.totalPurchasedInTradeToken,0),0, 0))+" ")]}},{key:"item.tradeValue",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("usdCustom")(item.tradeValue,0))+" ")]}},{key:"item.roiLevel",fn:function(ref){
                  var item = ref.item;
return [(item.roiLevel === 'Refunded')?_c('div',{staticClass:"d-flex align-center justify-center text-h5 font-weight-bold success--text"},[_vm._v(" Refunded ")]):(item.roiLevel && +item.roiLevel > 0)?_c('div',{staticClass:"d-flex align-center success--text justify-center text-h5 font-weight-bold",staticStyle:{"margin-left":"-16px"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v("mdi-arrow-up")]),_vm._v(" "+_vm._s(item.roiLevel)+"X ")],1):_vm._e()]}}],null,true)}),_c('v-pagination',{staticClass:"mt-6",attrs:{"length":_vm.vm.totalPage},model:{value:(_vm.vm.page),callback:function ($$v) {_vm.$set(_vm.vm, "page", $$v)},expression:"vm.page"}})],1)],2)],1)],1),_c('company-footer')],1):_c('div',[_c('ComingSoon')],1)}
var staticRenderFns = []

export { render, staticRenderFns }